//sass-lint:disable no-qualifying-elements
div.boxed-text {
  background-color: #ebeff3;
  display: inline-block;
  font-family: "Lato", sans-serif;
  font-size: 12px;
  letter-spacing: 1px;
  margin-bottom: 8px;
  padding: 8px 10px 6px 10px;
  text-transform: uppercase;
}

span.highlighted-text {
  background-color: #ef1059;
  color: white;
  font-family: "Lato", sans-serif;
  font-size: 18px;
  line-height: 1.3;
  padding: 2px 1px;
}

div.custom-icon {
  align-items: center;
  display: flex;

  svg {
    width: 40px;
  }

  p {
    margin: 12px 0 0 8px;
  }
}
